import React from "react"
import { Link } from "gatsby"
import {RiArrowLeftSLine, RiCheckboxCircleLine} from "react-icons/ri"

import SEO from "../components/seo"
import Layout from "../components/layout"

const Thanks = () => (
  <Layout className="thanks-page">
    <SEO title="Thank you"/>
    <div className="wrapper" style={{
      textAlign: "center"
    }}>
      <RiCheckboxCircleLine style={{
        fontSize: "128px",
        color: "var(--primary-color)"
      }}/>
      <h1>送信が完了しました</h1>
      <p>お問い合わせありがとうございます。内容を拝見したのち、折り返しの連絡をさせていただきます。</p>
      <Link to="/" className="button"><RiArrowLeftSLine className="icon -left"/>TOPへ戻る</Link>
    </div>

  </Layout>
)

export default Thanks